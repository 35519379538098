import { AlignmentValues } from '../types'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { BoxProps } from '@dtx-company/shared-components/src'
import { DraftWrapper } from './DraftWrapper'
import { Editor, EditorState } from 'draft-js'
import { FC } from 'react'

interface DraftJSEditorProps {
  editorState: EditorState
  textAlignment: AlignmentValues
  onChange?: (state: EditorState) => void
  containerProps?: BoxProps
  readOnly?: boolean
  placeholder?: string
  onFocus?: () => void
  onBlur?: () => void
  spellCheck?: boolean
}

export const DraftJSEditor: FC<DraftJSEditorProps> = ({
  editorState,
  onChange = () => {
    return
  },
  textAlignment,
  containerProps,
  readOnly = false,
  placeholder,
  onFocus,
  onBlur,
  spellCheck
}) => {
  return (
    <DraftWrapper>
      <Box
        className="draft-editor-wrapper"
        width="100%"
        {...containerProps}
        cursor={readOnly ? 'default' : 'text'}
      >
        <Editor
          editorState={editorState}
          onChange={onChange}
          placeholder={placeholder}
          textAlignment={textAlignment}
          readOnly={readOnly}
          onFocus={onFocus}
          onBlur={onBlur}
          spellCheck={spellCheck}
        />
      </Box>
    </DraftWrapper>
  )
}
