import { AlignmentValues } from './RichTextEditor/types'
import { Dispatch, FormEvent } from 'react'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'

export interface InlineTextBoxEditorProps {
  curr: LinkType
  dispatch: Dispatch<any>
  deleteWidget: () => void
  mobileManager: boolean
  charCount?: number
  setCharCount?: (value: number) => void
  disabled?: boolean
}

export interface TextBoxSectionFormState {
  title: string
  setTitle: (title: string) => void
  richText: string
  setRichText: (state: string) => void
  richTextAlignment: AlignmentValues
  setRichTextAlignment: (a: AlignmentValues) => void
  textType: TextTypes
  setTextType: (textType: TextTypes) => void
  linkTheme: LinkType['linkTheme']
  setLinkTheme: (linkThemeInput: Partial<LinkType['linkTheme']>) => void
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void
  disabled: boolean
  edit: boolean
  errors: {
    title: string
  }
}

export interface UseTextBoxSectionFormStateArgs {
  order: number
  handleClose: () => void
  curr?: LinkType
}

export enum TextTypes {
  HEADER = 'header',
  SUBHEADER = 'subheader',
  BODY = 'body'
}
