import { ActionDataTypes } from '../types'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ChangeEvent, FC, useState } from 'react'
import { InlineInput } from '@dtx-company/shared-components/src/components/atoms/InlineInput'
import { InlineTextBoxEditorProps } from './types'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { TEXTBOX_MAX } from '../validation'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { clearPreviewLink } from '@dtx-company/inter-app/src/redux/slices/linkEditor/linkEditorSlice'
import { getFontSize, toTextTypeOrDefault } from './utils'
import { theme } from '@dtx-company/shared-components/src'
import { useDebouncedCallback } from 'use-debounce'
import { useSubmitWidget } from '../submitUtils'
import { v4 as uuid } from 'uuid'

export const InlineTextBoxEditor: FC<InlineTextBoxEditorProps> = ({
  curr,
  dispatch,
  deleteWidget,
  mobileManager,
  charCount,
  setCharCount,
  disabled
}: InlineTextBoxEditorProps) => {
  const id = curr.id || uuid()
  const submitWidget = useSubmitWidget()
  const [focus, setFocus] = useState(false)

  const onInputChange = useDebouncedCallback(
    async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
      if (e.target.value.length > TEXTBOX_MAX) {
        e.target.value = e.target.value.substring(0, TEXTBOX_MAX)
      }
      if (setCharCount) setCharCount(e.target.value.length)

      const actionData: ActionDataTypes['textBoxSection'] = {
        textType: curr.actionData.textType,
        title: e.target.value
      }

      await submitWidget({
        curr: curr,
        actionData,
        handleClose: () => {
          dispatch(clearPreviewLink({}))
        },
        widgetType: 'textBoxSection',
        fields: {
          id,
          order: curr.order
        }
      })
    },
    500
  )

  // delete the widget if they finish editing it with no content
  const onBlurHandler = (value: string): void => {
    setFocus(false)
    if (value.length === 0) {
      deleteWidget()
    }
  }

  const fontSize = getFontSize(curr.linkTheme, curr.actionData)
  const colorFromThemeOrDefault =
    curr.linkTheme?.active && curr.linkTheme.fontColor
      ? curr.linkTheme.fontColor
      : 'flowpage.contrastText'
  const color = mobileManager ? colorFromThemeOrDefault : 'primary.black'
  return (
    <Box alignItems="center" width="100%" opacity={disabled ? '.35' : '1'}>
      <InlineInput
        disabled={disabled}
        type="text"
        defaultValue={curr.actionData.title || ''}
        placeholder={focus ? '' : `Enter ${toTextTypeOrDefault(curr.actionData.textType)} text`}
        maxWidth={[null, null, '100%']}
        fontSize={fontSize}
        inputProps={{
          textAlign: 'center',
          color,
          border: mobileManager ? `1px dashed ${theme.colors.secondary.disabled}` : 'none',
          borderRadius: '4px'
        }}
        id={`${curr.id}-textbox-input`}
        borderRadius="4px"
        onChange={e => onInputChange(e)}
        onBlur={e => onBlurHandler(e.target.value)}
        onFocus={() => setFocus(true)}
      />
      {!mobileManager && focus && (
        <>
          <Spacer ml="8px" />
          <Text variant="body/small" color="primary.grey">{`${
            charCount ?? '0'
          }/${TEXTBOX_MAX}`}</Text>
        </>
      )}
    </Box>
  )
}
