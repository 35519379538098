import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { DraftJSEditor } from './RichTextEditor/DraftJSEditor'
import { FC, useCallback } from 'react'
import { FlowpageProps } from '../types/FlowpageProps'
import { InlineTextBoxEditor } from './InlineTextBoxEditor'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { convertJSONtoEditorState } from './RichTextEditor/utils'
import { getFontSize, getSemanticHtmlElement } from './utils'
import { getLinkStyleValuesFromTheme } from '../../../components/flowpage/FlowpageLinkCard/utils/getLinkStyleValuesFromTheme'
import { sendSuccessNotification } from '@dtx-company/inter-app/src/utils/notifications'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCurrentPagePermissions } from '../../../utils/useGetCurrentPagePermissions'
import { useDeleteLinkWithAutosave } from '../deleteUtils'
import { useDispatch } from 'react-redux'
import { useFlowpageTheme } from '../../../components/profile/Customize/hooks'
import { useIsMobile } from '../../../utils/main'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'
import messages from '@dtx-company/true-common/src/constants/messages'

/** Component that renders on the Flowpage for the TextBoxSection */
export const Flowpage: FC<FlowpageProps> = ({ link, editLinkMode }: FlowpageProps) => {
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const { isViewer } = useAuthState()
  const { hasViewOnlyAccess } = useCurrentPagePermissions()
  const disabledInput = isViewer || hasViewOnlyAccess
  const deleteLink = useDeleteLinkWithAutosave()

  const deleteWidget = useCallback(async (): Promise<void> => {
    try {
      await deleteLink({
        linkId: link.id,
        linkOrder: link.order
      })

      link.provider === LinkProvider.WIDGET
        ? events.userDeletedWidget({ link_id: link.id })
        : events.userDeletedLink({ link_id: link.id })
      sendSuccessNotification(messages.profile.textBox.delete)
    } catch (err) {
      console.error(err)
    }
  }, [deleteLink, link.id, link.order, link.provider])

  const { theme: pageTheme } = useFlowpageTheme()
  if (isMobile && editLinkMode) {
    return (
      <InlineTextBoxEditor
        disabled={disabledInput}
        curr={link}
        dispatch={dispatch}
        deleteWidget={deleteWidget}
        mobileManager
      />
    )
  } else {
    const semanticTag = getSemanticHtmlElement(link.actionData)
    const fontSize = getFontSize(link.linkTheme, link.actionData)
    const { fontColor: color } = getLinkStyleValuesFromTheme(pageTheme, link.linkTheme)
    if (link?.actionData?.richText?.length) {
      const editorState = convertJSONtoEditorState(link.actionData.richText)
      return (
        <Box width="100%" data-testid="rich-text-display-container" alignItems="center">
          <DraftJSEditor
            editorState={editorState}
            textAlignment={link.actionData.richTextAlignment}
            readOnly={true}
            containerProps={{
              color: color ? color : 'flowpage.contrastText',
              fontFamily: pageTheme?.fontFamily
            }}
          />
        </Box>
      )
    } else {
      return (
        <Box width="100%" justifyContent="center" alignItems="center">
          <Text
            as={semanticTag}
            lineHeight="unset"
            fontSize={fontSize}
            mr="4px"
            ml="4px"
            wordBreak="break-word"
            maxWidth="100%"
            fontWeight={semanticTag === 'h2' ? 'bold' : 'regular'}
            textAlign="center"
            color={color ? color : 'flowpage.contrastText'}
          >
            {link.actionData.title}
          </Text>
        </Box>
      )
    }
  }
}
