import { ActionDataTypes, LinkType } from '../types'
import { DEFAULT_LINK_TITLE_FONT_SIZE } from '@dtx-company/true-common/src/constants/page'
import { TextTypes } from './types'

export const TEXT_TYPE_TO_PX_VALUE = {
  [TextTypes.HEADER]: 32,
  [TextTypes.SUBHEADER]: 24,
  [TextTypes.BODY]: 16
}

export const TEXT_TYPE_TO_PT_VALUE = {
  [TextTypes.HEADER]: 24,
  [TextTypes.SUBHEADER]: 18,
  [TextTypes.BODY]: 12
}

export const TEXT_TYPE_TO_HTML_SEMANTIC_ELEMENT = {
  [TextTypes.HEADER]: 'h2',
  [TextTypes.SUBHEADER]: 'h3',
  [TextTypes.BODY]: 'p'
}

export const pxToPtUnits = (pxValue: number): number => {
  return pxValue * 0.75
}

/**
 * Function to retrieve the font size to be used for a textBoxSection link when
 * rendering on a flowpage  based on whether the size is specified by
 * `actionData.textType` (newer. for links without link-level styling), `actionData.size`
 * (depracated but not DB migrated away yet) or `linkTheme.textSize` (new. links with
 *  link-level styling)
 *
 * if `linkTheme.textSize` exists it takes precedence over all other values
 */
export const getFontSize = (
  linkTheme: LinkType['linkTheme'],
  actionData: ActionDataTypes['textBoxSection']
): string => {
  if (linkTheme?.active && linkTheme?.textSize) return linkTheme.textSize + 'pt'
  if (actionData?.textType) {
    const textType = toTextTypeOrDefault(actionData?.textType)
    return TEXT_TYPE_TO_PT_VALUE[textType] + 'pt'
  }
  if (actionData?.size) return pxToPtUnits(actionData.size) + 'pt'

  return DEFAULT_LINK_TITLE_FONT_SIZE
}

export const toTextTypeOrDefault = (potentialTextType: string): TextTypes => {
  return Object.values(TextTypes).includes(potentialTextType as TextTypes)
    ? (potentialTextType as TextTypes)
    : TextTypes.BODY
}

/**
 * Map `link.actionData.size` (in px) to a `TextTypes` for `link.actionData.textType`.
 * until 'textBoxSection' type links are migrated to have `link.actionData.textType`
 *
 * @deprecated to be removed once `link.actionData.size` is DB migrated away
 */
export const actionDataSizeToActionDataTextType = (pxSize: number): TextTypes => {
  const sizeToTextTypes = {
    '32': TextTypes.HEADER,
    '24': TextTypes.SUBHEADER,
    '16': TextTypes.BODY
  }

  const sizeIndex = pxSize.toString() as keyof typeof sizeToTextTypes
  if (Object.keys(sizeToTextTypes).includes(sizeIndex)) {
    return sizeToTextTypes[sizeIndex]
  }

  return TextTypes.BODY
}

/**
 * For a given TextType value in `actionData.textType` return a string representing the
 * closest HTML semantic element
 */
export const getSemanticHtmlElement = (actionData: ActionDataTypes['textBoxSection']): string => {
  let textType = TextTypes.BODY
  if (actionData.textType) textType = toTextTypeOrDefault(actionData.textType)
  // Legacy links. Remove once actionData.size has been DB migrated away - AF
  else if (actionData.size) textType = actionDataSizeToActionDataTextType(actionData.size)

  return TEXT_TYPE_TO_HTML_SEMANTIC_ELEMENT[textType]
}
